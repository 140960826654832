<template>
  <div class="home-view">
    <Vcode :show="isShow" @success="success" @close="close" />
    <div class="login-view">
      <div class="login-view__div">
        <img class="title-img" src="../assets/images/home-title.png" alt="" />
        <div class="login-form">
          <!-- 未登录，账号登录 -->
          <template v-if="!isStatusIng">
            <input
              v-model="loginPhone"
              type="tel"
              placeholder="请输入手机号"
              maxlength="11"
              name="mobile"
            />
            <div class="verify">
              <input
                v-model="loginCode"
                placeholder="请输入验证码"
                maxlength="6"
              />
              <div
                v-if="!disabledClick"
                @click="sendPhoneMsg"
                class="verify-code"
              >
                获取验证码
              </div>
              <div v-else class="verify-code">{{ calTime }}秒后重试</div>
            </div>
            <van-button
              @click="userLogin"
              type="primary"
              block
              class="submit-btn"
            >
              立即查询
            </van-button>
          </template>
          <!-- 登录中，直接开始查询-->
          <template v-else>
            <div class="phone-ing">您好，{{ phoneNumber }}</div>
            <van-button
              @click="$router.push({ path: '/search' })"
              type="primary"
              block
              class="submit-btn"
            >
              立即查询
            </van-button>
          </template>
          <div class="agree-flex">
            <van-checkbox
              style="margin-right: 5px"
              shape="square"
              icon-size="14px"
              v-model="isAgree"
            />
            <div @click="isAgree = !isAgree">我已阅读并同意</div>
            <div @click="linkNotify" class="text">《用户服务协议》</div>
          </div>
        </div>
      </div>
    </div>
    <!--    <img style="width: 100%" src="../assets/images/img-1.png" alt="" />-->
    <!--    <img style="width: 100%" src="../assets/images/img-2.png" alt="" />-->
    <div class="author-view">
      <p class="author-view__let">(温馨提示：本报告非央行征信报告)</p>
      <p>
        关于恒信天下：恒信天下严格按照相关法律法规及监管单位的要求合法合规经营，若您对查询结果有异议，可关注恒信天下服务号或者在线客服，发起异议处理。
      </p>
      <p>
        江苏恒信天下信息科技有限公司合肥分公司&nbsp;&nbsp;&nbsp;&nbsp;版权所有
      </p>
      <div class="flex-p">
        <div @click="toChat">在线客服</div>
        <!--        <div style="width: 20px" />-->
        <!--        <div @click="callOur">联系我们</div>-->
      </div>
      <p>
        <a href="http://beian.miit.gov.cn/" target="_blank">
          苏ICP备2024071811号-1
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import { sendMsgCode, phoneLogin, checkLogin } from "@/utils/api";
import { getToken } from "@/utils/cookie";

export default {
  name: "HomeView",
  components: { Vcode },
  data() {
    return {
      phoneNumber: "",
      isStatusIng: false, //false:未登录；true:登录中
      loginPhone: "",
      loginCode: "",
      isAgree: true,
      isShow: false,
      calTime: 60, //短信验证计时
      disabledClick: false,
    };
  },
  mounted() {
    //推广标识
    let bd_vid = this.$route.query.bd_vid;
    if (bd_vid) sessionStorage.setItem("bd_vid", bd_vid);

    //推广标识
    let keyword = this.$route.query.keyword;
    if (keyword) sessionStorage.setItem("keyWord", keyword);

    //检查登录状态，token是否可用
    if (getToken()) {
      checkLogin().then((resp) => {
        this.isStatusIng = true;
        this.phoneNumber = resp.data;
      });
    }
  },
  methods: {
    callOur() {
      this.$router.push({
        path: "/contack",
      });
    },
    toChat() {
      window.open(
        "https://affim.baidu.com/unique_53070114/mobile/chat?siteId=20173242&userId=53070114&siteToken=83852f38e261f082fc5491fe256e7674"
      );
    },
    /**
     * 发送手机验证码
     */
    sendPhoneMsg() {
      let regu = /^1[3-9][0-9]{9}$/;
      if (regu.test(this.loginPhone)) {
        this.isShow = true;
      } else {
        this.$toast("手机号格式错误");
      }
    },
    /**
     * 跳转用户协议
     */
    linkNotify() {
      this.$router.push({
        path: "/about-x",
      });
    },
    close() {
      this.isShow = false;
    },
    async success() {
      const resp = await sendMsgCode({ phone: this.loginPhone });
      if (resp.code !== 200) return;
      this.$toast("验证码发送成功");

      //图片滑块验证通过，发送验证码
      this.isShow = false;
      this.disabledClick = true;
      let codeTimer = () => {
        this.calTime--;
        setTimeout(() => {
          if (this.calTime <= 0) {
            this.disabledClick = false;
            this.calTime = 60;
          } else {
            codeTimer();
          }
        }, 1000);
      };
      codeTimer();
    },
    /**
     * 登录
     */
    async userLogin() {
      if (this.isAgree === false) {
        this.$toast("您需要同意用户服务协议");
        return false;
      }
      if (!(this.loginPhone || this.loginCode)) {
        this.$toast("请填写手机号和验证码");
        return false;
      }
      phoneLogin({
        phone: this.loginPhone,
        captcha: this.loginCode,
        keyword: sessionStorage.getItem("keyWord"),
      }).then(() => {
        this.$router.push({
          path: "/search",
        });
      });
    },
  },
};
</script>

<style lang="less">
@primary-color: #cf2500;
@font-color: #ff6325;
.home-view {
  background: #f6f6f6;
  .phone-ing {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    color: #111111;
    margin-top: 68px;
  }
  .login-view {
    width: 100%;
    height: 667px;
    background-color: #f7eed6;
    background-image: url("../assets/images/back-view.png");
    background-repeat: no-repeat;
    background-position-y: top;
    background-size: 100%;
    overflow: hidden;

    .login-view__div {
      width: 300px;
      margin: auto;

      .title-img {
        width: 100%;
        margin: 40px auto 40px auto;
        display: block;
      }

      input {
        //font-size: 15px;
        width: calc(100% - 30px);
        height: 100%;
        padding: 15px;
        border-radius: 5px;
        background: #fff !important;
        border: 1px solid @primary-color !important;
      }

      .verify {
        position: relative;
        margin-top: 20px;
      }

      .verify-code {
        position: absolute;
        right: 0;
        color: @font-color;
        border-left: 1px solid #c8d4e2;
        top: 10px;
        padding: 5px 20px;
        cursor: pointer;
      }

      .submit-btn {
        margin-top: 20px;
      }

      .van-button--primary {
        background-color: @primary-color;
        border-color: @primary-color;
      }

      .agree-flex {
        display: flex;
        margin-top: 20px;
        justify-content: center;

        .text {
          color: @font-color;
        }
      }

      .van-checkbox__icon--checked .van-icon {
        background-color: @primary-color;
        border-color: @primary-color;
      }

      .van-checkbox__icon .van-icon {
        border-color: @primary-color;
      }
    }
  }

  .author-view {
    padding: 10px;
    .author-view__let {
      font-size: 10px;
    }
    p {
      text-align: center;
      font-size: 12px;
    }

    .flex-p {
      display: flex;
      justify-content: center;

      div {
        text-decoration: underline;
      }
    }
  }
}
</style>
